import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import SiteStyles from '../../Site.module.css';
import Styles from './MPPanel.module.css';
import TextStyles from '../../styles/text.module.css';
import ButtonStyles from '../../styles/buttons.module.css';
import Config from '../../../config.json';
import { ArrowRightIcon, ChatIcon, StarIcon } from '../../Icons';
import getCountryEmoji from '../getCountryEmoji';
import RatingView from '../../App/subcomponents/RatingView/RatingView';

const MPPanel = ({ consultant, dontNavigate = false, voucherMinOrderValue = null, voucherValue = null }) => {
    let navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const visitProfile = () => {
        navigate(`/consultant/${consultant._id}`);
    };

    const checkLoggedIn = () => {
        axios.get(`${Config.API_BASE_URL}/auth/check-headers`).then(res => {
            if (res.data.hasAuthHeaders) {
                setIsLoggedIn(true);
            }
        }).catch(err => {
            console.log(err);
        });
    };

    useEffect(() => {
        checkLoggedIn();

        // Check mobile size for conditional render 
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const messageConsultant = () => {
        if (isLoggedIn) {
            axios.post(`${Config.API_BASE_URL}/messages/create/${consultant._id}`).then(res => {
                if (!dontNavigate) {
                    navigate('/app/messages/', { state: { conversationId: res.data._id } });
                }
            }).catch(err => {
                console.log('Error has occurred when fetching consultant');
            });
        } else {
            navigate('/register');
        }
    };

    // Determine if voucher applies
    const voucherApplies = voucherValue !== null && (voucherMinOrderValue / 100) <= parseInt(consultant.hourlyRate);
    const discountedRate = voucherApplies ? consultant.hourlyRate - (voucherValue / 100) : consultant.hourlyRate;

    return (
        <div className={Styles.ConsultantPanel}>

            {/* Image */}
            <div style={{ position: 'relative', cursor: 'pointer', }} onClick={visitProfile}>
                {/* Profile Picture */}
                <img src={`${Config.IMAGES_BASE_URL}/profilepics/${consultant.profilePicture}`}
                    style={{ width: '100%', height: 248, objectFit: 'cover', }} />

                {/* Gradient */}
                <div style={{ position: 'absolute', width: '100%', height: '100%', bottom: 0, borderRadius: '0px 0px 3px 3px', background: 'linear-gradient(0deg, black 12%, transparent 36%)' }} />

                {/* Name & Badges */}
                <div style={{ position: 'absolute', bottom: 0, padding: '0px 8px', width: '100%', }}>
                    {/* Badges */}
                    <div style={{ display: 'flex', gap: 4, height: 'min-content' }}>
                        {consultant.consultantComputedBadges.includes('popular') && <p className={`${TextStyles.BoldPillLight} ${Styles.Badge}`}>Popular</p>}
                        {consultant.consultantComputedBadges.includes('new') && <p className={`${TextStyles.BoldPillLight} ${Styles.Badge}`}>New</p>}
                        {/* {consultant.consultantComputedBadges.includes('recentactivity') && <p className={`${TextStyles.BoldPillLight} ${Styles.Badge}`}>Recently Active</p>} */}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', }}>
                        {/* Name */}
                        <p className={TextStyles.Title} style={{ color: 'white', marginBottom: 0, fontSize: '18pt', }}>{consultant.name}</p>

                        {/* Country */}
                        {getCountryEmoji(consultant.consultantCountry)}
                    </div>
                </div>

                {/* Review Avg & Count */}
                {!isNaN(consultant.consultantReviewCount) && consultant.consultantReviewCount > 0 && <p className={`${TextStyles.Bold} ${Styles.Reviews}`}>
                    {consultant.consultantReviewAvg.toFixed(1)}
                    <StarIcon width={14} height={14} ml={4} mb={3} mr={3} />
                    <span style={{ color: '#555', fontWeight: '600', fontSize: '11pt' }}>({consultant.consultantReviewCount})</span>
                </p>}
            </div>


            <div >
                <div style={{ padding: '0px 8px' }} onClick={visitProfile}>
                    <p className={TextStyles.Bold} style={{ fontWeight: 700, marginBottom: 4, marginTop: 4, fontSize: '14pt' }}>{consultant.taglineOne}</p>
                    <p className={TextStyles.Body} style={{ color: '#444', lineHeight: '16pt', }}>{consultant.taglineTwo}</p>
                </div>

                <div className={Styles.BottomBar}>
                    {/* Price */}
                    <p className={TextStyles.Bold} style={{ fontSize: '14pt', zIndex: 10000, marginBottom: -4, }} onClick={visitProfile}>
                        {voucherApplies ? (
                            <>
                                <span style={{ textDecoration: 'line-through', opacity: 0.7, fontWeight: 500, fontSize: 'small' }}>
                                    ${consultant.hourlyRate}
                                </span>
                                <span style={{ color: 'black', marginLeft: 4, fontSize: '16pt' }}>
                                    ${discountedRate}/hr
                                </span>
                            </>
                        ) : (
                            <>
                                <span style={{ fontSize: '16pt' }}>${consultant.hourlyRate}/hr</span>
                            </>
                        )}
                    </p>

                    <div style={{ display: 'flex', gap: 8, paddingBottom: 8 }}>
                        <button className={ButtonStyles.StandardDark} style={{ padding: '3px 12px' }} onClick={messageConsultant}>
                            Chat
                            <ChatIcon color="white" strokeWidth='3' ml={6} height={16} width={16} />
                        </button>

                        <button className={ButtonStyles.StandardDark} style={{ padding: '3px 12px' }} onClick={visitProfile}>
                            More
                            <ArrowRightIcon color="white" strokeWidth='3' ml={6} height={16} width={16} />
                        </button>
                    </div>
                </div>
            </div>



        </div >
    );
};

// Function to map state from store to props
const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.isAuthenticated,
    };
};

// Use the connect higher-order component to connect MPPanel to the Redux store
export default connect(mapStateToProps)(MPPanel);
